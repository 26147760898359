export const AUTH_KIT = {
  NAME: "_auth",
  DOMAIN: window.location.hostname,
};

export const COOKIES_KEYS = {
  AUTH_STATE: "_auth_state",
  USER_FEATS: "_user_feats",
};
export const STORAGE_KEYS = {
  OTP_EXPIRED: "OTP_EXPIRED",
};
