import { IResponseData } from "@/utilities/types/requests.ts";
import useApi from "@/hooks/useApi.tsx";
import { useTableServer } from "@/hooks/useTableServer.tsx";
import { useCallback, useEffect, useState } from "react";
import ControlledTable from "@/components/controlled-table";
import { useColumn } from "@/hooks/use-column.ts";
import { Roles } from "@/utilities/types/Users.ts";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { Button, SelectOption, Title } from "rizzui";
import { GetColumns } from "./columns";
import useProfile from "@/hooks/use-profile";
import LandingPagesRepository from "@/utilities/repositories/LandingPages";
import { backPrev } from "@/utilities/functions";
import { routes } from "@/config/routes";
import SettingsRepository from "@/utilities/repositories/Settings";
import { ColumnsType } from "rc-table";
import useRowRefs from "@/hooks/use-row-refs";
import useScrollToRow from "@/hooks/use-scroll-to-row";
import ProjectSelect from "@/components/project-select";
import {
  landingPageSchema,
  TLandingPageSchemaForm,
} from "@/utilities/validators/landing-page";
import { zodResolver } from "@hookform/resolvers/zod";
import { ILandingPage } from "@/utilities/types/LandingPage";
import { useForm, Controller } from "react-hook-form";
import UserSelect from "@/components/user-select";
import LandingPageOptionRepository from "@/utilities/repositories/LandingPageOptions";
import { SUFFIX_DOMAIN } from "@/config/constants";

const ProjectLandingPagesTable = () => {
  const { id: projectId } = useParams<{ id: string }>();

  const { profile } = useProfile();

  const {
    handleReset,
    isLoading,
    tableData,
    currentPage,
    totalItems,
    handleSort,
    handlePaginate,
    pageSize,
    handleChangePageSize,
    refresh,
  } = useTableServer({
    server: {
      request: LandingPagesRepository.getLandingPages,
    },
    initialFilterState: {
      projectId,
      userId: profile?.role === Roles.ADMIN ? undefined : profile?.id,
    },
  });

  const [customTableData, setCustomTableData] =
    useState<(ILandingPage & { isAdd?: boolean })[]>(tableData);

  const customData = useCallback(() => {
    if (tableData) {
      setCustomTableData(tableData);
    }
  }, [tableData]);

  //Set table data
  useEffect(() => setCustomTableData(tableData), [customData, tableData]);

  const rowRefs = useRowRefs(customTableData);

  useScrollToRow({ rowRefs });

  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isAddingRow, setIsAddingRow] = useState<boolean>(false);

  const {
    request: getOption,
    response,
    error,
  } = useApi({
    request: LandingPageOptionRepository.getLandingPageOption,
  });

  const { request: createLandingPage } = useApi<IResponseData<ILandingPage>>({
    request: LandingPagesRepository.createLandingPage,
    enableToast: true,
  });

  const { request: updateLandingPage } = useApi<IResponseData<ILandingPage>>({
    request: LandingPagesRepository.updateLandingPage,
    enableToast: true,
  });

  const { request: removeLandingPage } = useApi<IResponseData<ILandingPage>>({
    request: LandingPagesRepository.removeLandingPage,
    enableToast: true,
  });

  const { request: attachOption } = useApi({
    request: LandingPageOptionRepository.attachOption,
  });

  const { request: activeOption } = useApi({
    request: LandingPageOptionRepository.activeOption,
  });

  const handleAttachOption = async (optionId: string, ldpId: string) => {
    try {
      await attachOption({
        landingPageId: ldpId,
        layoutOptionId: optionId,
      });
      await activeOption({
        landingPageId: ldpId,
        layoutOptionId: optionId,
        active: true,
      });
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  const form = useForm<TLandingPageSchemaForm>({
    defaultValues: {
      name: customTableData[0]?.name ?? "",
      domain: customTableData[0]?.domain ?? "",
      project: {
        value: projectId ?? "",
        label: "",
      },
      user: profile?.role === Roles.ADMIN ? "" : profile?.id || "",
    },
    mode: "onChange",
    resolver: zodResolver(landingPageSchema),
  });

  const { setValue, watch } = form;
  const formValues = watch();

  const onHeaderCellClick = (value: string) => ({
    onClick: () => {
      handleSort(value);
    },
  });

  const onAddLandingPage = async ({
    layoutOptionId,
    ...data
  }: TLandingPageSchemaForm) => {
    await createLandingPage({
      ...data,
    })
      .then(async (res) => {
        setIsAdding(false);
        await handleAttachOption(layoutOptionId || "", res?.data?.id || "");
        refresh(); // Recall AP
      })
      .catch((err: any) => {
        toast.error(err?.message || "Something went wrong");
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onUpdateItem = async (
    id: string,
    data: Partial<TLandingPageSchemaForm>
  ) => {
    await updateLandingPage(id, data);
    refresh(); // Recall API
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onDeleteItem = async (id: string) => {
    if (isAdding) {
      setCustomTableData(tableData);
      setIsAdding(false);
      return;
    }
    await removeLandingPage(id);
    refresh(); // Recall API
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const columns = GetColumns({
    data: customTableData,
    onUpdateItem,
    onDeleteItem,
    onHeaderCellClick,
    projectId: projectId ?? "",
    reset: handleReset,
    role: Roles.ADMIN,
    onAddLandingPage,
    profile,
    form,
    ldpOtions: response?.data?.items || [],
    // or specify your form's type, e.g., FormData
  });

  const { visibleColumns } = useColumn(columns);

  const addNewRow = useCallback(
    async (
      projectValue: string,
      userValue: string,
      isAdding: boolean,
      isAddingRow: boolean
    ) => {
      if (projectValue && userValue && isAdding && isAddingRow) {
        const response = await SettingsRepository.generateUniqeDomain(
          projectValue || ""
        );

        const subDomain =
          response?.data ?? Math.random().toString(36).substring(7);
        if (subDomain) {
          setValue("domain", `${subDomain}${SUFFIX_DOMAIN}`);
          setValue("name", subDomain);
        }
        setCustomTableData([
          {
            ...customTableData[0],
            isAdd: true,
            id: "addNew",
            name: subDomain,
            domain: subDomain,
            projectId: projectValue,
            userDomainId: userValue,
          },

          ...customTableData,
        ]);
        setIsAddingRow(false);
      }
    },
    [customTableData, setValue]
  );

  useEffect(() => {
    if (
      formValues?.project?.value &&
      formValues?.user &&
      isAdding &&
      isAddingRow
    ) {
      addNewRow(
        formValues?.project?.value,
        formValues?.user,
        isAdding,
        isAddingRow
      );
    }
  }, [
    addNewRow,
    formValues,
    formValues?.project?.value,
    formValues?.user,
    isAdding,
    isAddingRow,
  ]);

  useEffect(() => {
    (async () => {
      await getOption({
        page: 1,
        limit: 50,
      });
    })();
  }, []);

  return (
    <div className={"col-span-full mt-4"}>
      <div className="mb-4 w-full flex flex-col lg:flex-row lg:items-center items-start gap-2.5 @container justify-between">
        <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
          Landing Pages
        </Title>
        <div className="flex flex-col w-full lg:w-fit lg:flex-row gap-3">
          <div className="flex flex-1 lg:flex-row order-3 lg:order-1  flex-col gap-3">
            {!projectId && (
              <div className="lg:w-[300px] w-full order-3 lg:order-2">
                <Controller
                  control={form.control}
                  name="project"
                  render={({ field, fieldState, formState }) => {
                    return (
                      <ProjectSelect
                        label=""
                        size="md"
                        placeholder="Select Project"
                        className="col-span-full"
                        dropdownClassName="z-[999]"
                        {...field}
                        {...formState}
                        value={field.value as SelectOption}
                        error={fieldState.error?.message}
                        user={watch("user")}
                      />
                    );
                  }}
                />
              </div>
            )}
            {profile?.role === Roles.ADMIN && (
              <div className="lg:w-[300px] w-full order-4 lg:order-1">
                <Controller
                  control={form.control}
                  name="user"
                  render={({ field, fieldState, formState }) => (
                    <UserSelect
                      label=""
                      size="md"
                      placeholder="Select User"
                      className="col-span-full h-10"
                      dropdownClassName="z-[999]"
                      {...field}
                      {...formState}
                      error={fieldState.error?.message}
                      projectId={watch("project")?.value}
                    />
                  )}
                />
              </div>
            )}
          </div>
          <Button
            disabled={isAdding}
            onClick={() => {
              setIsAdding(true);
              setIsAddingRow(true);
            }}
            className="order-2 lg:order-3"
          >
            Add new
          </Button>

          <Link
            to={backPrev(routes.project.listing)}
            className={"mr-2 order-1 lg:order-4 flex-1 lg:w-fit w-full ml-auto"}
          >
            <span
              className={
                "px-4 lg:w-fit w-full text-center py-2 inline-block border border-solid border-black rounded-md"
              }
            >
              Back
            </span>
          </Link>
        </div>
      </div>
      <ControlledTable
        variant="modern"
        data={customTableData}
        isLoading={isLoading}
        showLoadingText={true}
        columns={
          visibleColumns as unknown as ColumnsType<
            ILandingPage & {
              isAdd?: boolean;
            }
          >
        }
        paginatorOptions={{
          pageSize,
          setPageSize: handleChangePageSize,
          total: totalItems,
          current: currentPage,
          onChange: (page: number) => handlePaginate(page),
        }}
        className="rounded-md border border-muted text-sm shadow-sm [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:h-60 [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:justify-center [&_.rc-table-row:last-child_td.rc-table-cell]:border-b-0 [&_thead.rc-table-thead]:border-t-0"
      />
    </div>
  );
};

export default ProjectLandingPagesTable;
