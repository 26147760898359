"use client";

import cn from "@/utils/class-names";
import Logo from "@/components/logo";
import ProfileCardMenu from "@/layouts/carbon/profile-card-menu";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import dynamic from "next/dynamic";
import SimpleBar from "simplebar-react";
import { CarbonSidebarMenu } from "./carbon-sidebar-menu";
import { Link } from "react-router-dom";

import useProfile from "@/hooks/use-profile";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ELangCode } from "@/utilities/enums/Language";
import { LANGUAGE_KEY } from "@/config/constants";
import useApi from "@/hooks/useApi";
import { IResponseData } from "@/utilities/types/requests";
import { IUserData, Roles } from "@/utilities/types/Users.ts";
import UserRepository from "@/utilities/repositories/Users.ts";
import DynamicLayoutButton from "@/app/app-shared/advanced-features/features/DynamicLayoutButton";
import { useAdvanceFeatures } from "@/hooks/use-advance-features";

const ASSET_BASE_URL = import.meta.env.VITE_ASSET_BASE_URL as string;

const NeedSupport = dynamic(() => import("@/layouts/carbon/need-support"), {
  ssr: false,
});

export function CarbonSidebar({ className }: { className?: string }) {
  const { profile, setProfile } = useProfile();
  const [urlImage, setUrlImage] = useState<string>(
    "https://isomorphic-furyroad.s3.amazonaws.com/public/avatars/avatar-12.webp"
  );

  const { i18n } = useTranslation();

  const { request: getAgencySetting, response: agencySettingResponse } = useApi<
    IResponseData<IUserData>
  >({
    request: UserRepository.getMe,
  });

  const { enabledLayout } = useAdvanceFeatures();

  useEffect(() => {
    if (profile?.photo?.urls[0])
      setUrlImage(`${ASSET_BASE_URL}${profile.photo.urls[0].url}`);
  }, [profile]);

  useEffect(() => {
    getAgencySetting();
  }, []);

  useEffect(() => {
    if (agencySettingResponse?.data && profile) {
      setProfile({
        ...profile,
        dynamicLayoutMode: agencySettingResponse?.data?.dynamicLayoutMode,
        photo: agencySettingResponse?.data?.photo,
        domains: agencySettingResponse.data.domains,
        company: agencySettingResponse?.data?.company,
        userFeatures: agencySettingResponse?.data?.userFeatures,
      });
    }
  }, [agencySettingResponse?.data]);

  const onChangeLanguage = (lang: ELangCode) => {
    i18n.changeLanguage(lang);
    localStorage.setItem(LANGUAGE_KEY, lang);
  };

  return (
    <aside
      className={cn(
        "fixed flex flex-col justify-between bottom-0 start-0 z-50 h-full w-[270px] border-e-2 border-gray-100 bg-transparent dark:bg-transparent 2xl:w-72",
        className
      )}
    >
      <div className="sticky top-0 z-40 bg-transparent px-6 pb-5 pt-5 dark:bg-transparent 2xl:px-8 2xl:pt-6">
        <Link
          to={"/"}
          aria-label="Site Logo"
          className="text-gray-800 hover:text-gray-900"
        >
          <Logo className="max-w-[200px]" />
        </Link>
      </div>

      <SimpleBar
        className={cn(
          "flex-1 overflow-auto [&_.simplebar-content]:flex [&_.simplebar-content]:h-full [&_.simplebar-content]:flex-col [&_.simplebar-content]:justify-between",
          profile?.role === Roles.ADMIN && "h-[calc(100%-105px)]",
          profile?.role === Roles.AGENCY && enabledLayout
            ? "h-[calc(100%-209px)]"
            : "h-[calc(100%-159px)]"
        )}
      >
        <CarbonSidebarMenu />

        {/* <div className="sticky bottom-0 bg-gray-0 dark:bg-gray-50 ">
          <NeedSupport
            title="Need Support?"
            text="Contact with one of our experts to get support."
            prefixIcon={<PiHeadsetBold className="h-5 w-5 text-gray-400" />}
            className="relative mx-6 before:absolute before:-start-6 before:bottom-full before:end-0 before:h-10 before:w-[calc(100%+48px)] before:bg-gradient-to-t before:from-gray-0 before:to-gray-0/30 before:dark:from-gray-50 before:dark:to-gray-50/30"
          />
        </div> */}
      </SimpleBar>
      {enabledLayout && (
        <div className="mx-[14px] my-1">
          <DynamicLayoutButton isSuperAdmin={profile?.role === Roles.ADMIN} />
        </div>
      )}

      <div className="gap-3 mt-auto bg-gray-0 px-6 pb-1 dark:bg-gray-50">
        <ProfileCardMenu
          title={profile?.firstName + " " + profile?.lastName}
          designation={
            profile?.role === Roles.AGENCY
              ? profile?.company || ""
              : profile?.role
          }
          placement="top"
          image={urlImage}
          avatarClassName="!w-10 !h-10"
          icon={
            <PiDotsThreeVerticalBold
              className={cn(
                "h-7 w-7 text-gray-400 transition-all group-hover:text-primary"
              )}
            />
          }
          className={cn("px-0 py-0")}
          buttonClassName="border-0 !border-t !border-gray-200 pt-5 px-0 rounded-none"
        />
      </div>
    </aside>
  );
}
