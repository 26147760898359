"use client";

import { useModal } from "@/app/shared/modal-views/use-modal";
import SocialLinkField from "@/components/RHFFields/SocialLinkField";
import SingleImageUpload from "@/components/ui/file-upload/single-image-upload";
import { Form } from "@/components/ui/form";
import useProfile from "@/hooks/use-profile";
import useApi from "@/hooks/useApi";
import SettingsRepository from "@/utilities/repositories/Settings";
import {
  TAgencySetting,
  TAgencySettingUpdate,
  TDomain,
} from "@/utilities/types/Setting";
import { IResponseData } from "@/utilities/types/requests";
import cn from "@/utils/class-names";
import {
  EditAgencySettingSchema,
  editAgencySettingSchema,
} from "@/utils/validators/edit-agency-setting.schema";
import { useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler } from "react-hook-form";
import toast from "react-hot-toast";
import { Button, Input, Tab, Textarea, Title } from "rizzui";
import CreateAgencySetting from "./CreateAgencySetting";
import QuillEditor from "@/components/ui/quille-editor/quill-editor";
import { IColorScheme } from "@/utilities/types/ColorScheme.ts";
import ColorSchemaField from "@/components/RHFFields/ColorSchemaField";
import ColorPickerPopover from "@/app/shared/colorpicker-popover.tsx";
import VisibleToggle from "@/components/visible-toggle";
import MultipleEmailsField from "@/components/RHFFields/MultipleEmailsField";
import UserRepository from "@/utilities/repositories/Users";
import ChatBotRepositories from "@/utilities/repositories/ChatBot";
import { ChatBotDTO } from "@/utilities/types/ChatBot";
import DomainInput from "@/components/ui/domain-input";
import { SUFFIX_DOMAIN } from "@/config/constants";
import TelegramBotSetting from "@/app/app-shared/telegram-bot";
import { head } from "lodash";

export default function EditAgencySetting() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { profile, setProfile } = useProfile();
  const [colorSchemes, setColorSchemes] = useState<IColorScheme[]>();
  const { openModal } = useModal();
  // const [topMessageBackground, setTopMessageBackground] = useState<
  //   string | undefined
  // >();
  const DEFAULT_VALUES = {
    id: "",
    domain: "",
    siteTitle: "",
    siteDescription: "",
    phoneNumber: "",
    topMessageBackground: "",
    topMessageText: "",
    agencyEmail: "",
    agencyName: "",
    siteLogo: {
      id: "",
      url: "",
    },
    headerLogo: {
      id: "",
      url: "",
    },
    footerLogo: {
      id: "",
      url: "",
    },
    coverImage: {
      id: "",
      url: "",
    },
    agencyPhoto: {
      id: "",
      url: "",
    },
    colorScheme:
      colorSchemes?.reduce(
        (acc, scheme) => {
          acc[scheme.header] = scheme.colors;
          return acc;
        },
        {} as Record<string, Record<string, string>>
      ) || ({} as Record<string, Record<string, string>>),
    socialLinks: [],
    promotionHeading: "",
    promotionBgColor: "",
    promotionSeoImage: {
      id: "",
      url: "",
    },
    promotionSeoDescription: "",
    promotionSeoTitle: "",
    upcomingSeoImage: {
      id: "",
      url: "",
    },
    upcomingSeoDescription: "",
    upcomingSeoTitle: "",
    topSeoImage: {
      id: "",
      url: "",
    },
    topSeoDescription: "",
    topSeoTitle: "",
    seoImage: {
      id: "",
      url: "",
    },
    seoDescription: "",
    twitterCreator: "",
    twitterSite: "",
    mobileMastheadImage: {
      id: "",
      url: "",
    },
    floatingButtonEnabled: false,
    salesTeamInfo: {
      name: "",
      registrationNumber: "",
      currentEa: "",
      licenseNumber: "",
    },
    additionalEmail: [],
    // measuringId: "",
    headerScript: "",
    bodyScript: "",
    enquiryScript: "",
    chatId: "",
  };
  const [reset, setReset] = useState<EditAgencySettingSchema>(DEFAULT_VALUES);

  const onOpenCreateAgencySettingModal = () =>
    openModal({
      view: (
        <CreateAgencySetting
          isModalView
          userId={profile?.id || ""}
          onRefetch={onFetchAgencySetting}
        />
      ),
      customSize: "900px",
    });

  const onSubmit: SubmitHandler<EditAgencySettingSchema> = async (formData) => {
    if (!formData?.id) {
      onOpenCreateAgencySettingModal();
      return;
    }

    const updatedSchemes = colorSchemes?.map((scheme: IColorScheme) => {
      if (formData.colorScheme[scheme.header]) {
        return {
          ...scheme,
          colors: formData.colorScheme[scheme.header],
        };
      }
      return scheme;
    });

    setColorSchemes(updatedSchemes);

    const dataToUpdate: TAgencySettingUpdate = {
      siteTitle: formData?.siteTitle || "",
      siteDescription: formData?.siteDescription || "",
      phoneNumber: formData?.phoneNumber || "",
      siteLogoId: formData?.siteLogo?.id || null,
      headerLogoId: formData?.headerLogo?.id || null,
      footerLogoId: formData?.footerLogo?.id || null,
      coverImageId: formData?.coverImage?.id || null,
      agencyPhotoId: formData?.agencyPhoto?.id || null,
      socialLinks:
        formData?.socialLinks?.map((item) => ({
          url: item?.url || "",
          type: item?.type || "",
          label: item?.label || "",
        })) || [],
      topMessage: {
        text: formData?.topMessageText || "",
        backgroundColor: formData?.topMessageBackground || "",
      },
      colorScheme: updatedSchemes,
      agencyEmail: formData?.agencyEmail || "",
      agencyName: formData?.agencyName || "",
      promotionHeading: formData?.promotionHeading || "",
      promotionBgColor: formData?.promotionBgColor || "",
      promotionSeoImageId: formData?.promotionSeoImage?.id || null,
      promotionSeoTitle: formData?.promotionSeoTitle || "",
      promotionSeoDescription: formData?.promotionSeoDescription || "",
      upcomingSeoDescription: formData?.upcomingSeoDescription || "",
      upcomingSeoTitle: formData?.upcomingSeoTitle || "",
      upcomingSeoImageId: formData?.upcomingSeoImage?.id || null,
      topSeoDescription: formData?.topSeoDescription || "",
      topSeoImageId: formData?.topSeoImage?.id || null,
      topSeoTitle: formData?.topSeoTitle || "",
      seoDescription: formData?.seoDescription || "",
      seoImageId: formData?.seoImage?.id || null,
      twitterCreator: formData?.twitterCreator || "",
      twitterSite: formData?.twitterSite || "",
      mobileMastheadImageId: formData?.mobileMastheadImage?.id || null,
      floatingButtonEnabled: !!formData?.floatingButtonEnabled,
      whatsapp: formData?.whatsapp || "",
      salesTeamInfo: {
        name: formData?.salesTeamInfo?.name || "",
        registrationNumber: formData?.salesTeamInfo?.registrationNumber || "",
        currentEa: formData?.salesTeamInfo?.currentEa || "",
        licenseNumber: formData?.salesTeamInfo?.licenseNumber || "",
      },
      headerScript: formData?.headerScript || "",
      bodyScript: formData?.bodyScript || "",
      enquiryScript: formData?.enquiryScript || "",
    };

    const telegramChatBotData: ChatBotDTO = {
      chatId: formData?.chatId || "",
      email: formData?.agencyEmail || "",
      userId: agencySettingData?.data?.userId || "",
      name:
        (import.meta.env.VITE_BASE_TELEGRAM_BOT_NAME as string) ||
        "property-development-tele-bot",
    };

    try {
      setIsLoading(true);
      const updateAgencySettingRes =
        await SettingsRepository.updateAgencySetting(
          formData?.id || "",
          dataToUpdate
        );

      if (formData && formData?.additionalEmail?.length > 0) {
        const additionalEmail = formData?.additionalEmail
          .map((item) => item.email)
          .filter((item) => {
            const mainEmail = formData?.agencyEmail;
            return item !== mainEmail;
          });

        await UserRepository.updateMe({
          additionalEmail,
          // measuringId: formData?.measuringId || undefined,
          // headerScript: formData?.headerScript || undefined,
          // bodyScript: formData?.bodyScript || undefined,
        });
        // TODO: chcek again if multple update
        const currentSettingChatBot = response?.data?.[0];

        await ChatBotRepositories.settingChatBot(
          telegramChatBotData,
          currentSettingChatBot?.id
        );

        const configId = agencySettingData?.data?.id;

        if (form?.formState?.dirtyFields?.domain && configId) {
          await SettingsRepository.createDomain({
            configId,
            name: `${formData?.domain}`,
            primary: true,
          });

          setReset({ ...reset, domain: formData?.domain });
        }
      }

      toast.success(`Updated agent setting successfully`);

      if (profile) {
        setProfile({
          ...profile,
          photo: updateAgencySettingRes?.data?.agencyPhoto,
        });
      }

      setIsLoading(false);
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
      setIsLoading(false);
    }
  };

  const { request: getAgencySetting, response: agencySettingData } = useApi<
    IResponseData<TAgencySetting>
  >({
    request: SettingsRepository.getAgencySetting,
  });

  const { request: getChatBotSetting, response } = useApi<any>({
    request: ChatBotRepositories.getAllChatBot,
  });

  const onFetchAgencySetting = () =>
    getAgencySetting()
      .then(async (res) => {
        if (!res?.data) return;
        const {
          siteTitle,
          siteDescription,
          phoneNumber,
          siteLogoId,
          topMessage,
          headerLogoId,
          footerLogoId,
          coverImageId,
          domains,
          colorScheme,
          socialLinks,
          siteLogo,
          footerLogo,
          coverImage,
          headerLogo,
          id,
          agencyEmail,
          agencyName,
          user: {
            email,
            additionalEmail,
            // , measuringId
          },
          agencyPhotoId,
          agencyPhoto,
          promotionHeading,
          promotionBgColor,
          promotionSeoImage,
          promotionSeoTitle,
          promotionSeoDescription,
          upcomingSeoDescription,
          upcomingSeoTitle,
          upcomingSeoImage,
          topSeoDescription,
          topSeoImage,
          topSeoTitle,
          seoDescription,
          seoImage,
          twitterSite,
          twitterCreator,
          mobileMastheadImage,
          floatingButtonEnabled,
          whatsapp,
          salesTeamInfo,
          headerScript,
          bodyScript,
          enquiryScript,
        } = res.data;

        setColorSchemes(colorScheme);

        const additionalEmailConvert = additionalEmail?.length
          ? additionalEmail.map((email) => ({ email }))
          : [];

        const chatBotResponse = await getChatBotSetting();

        const settingChatBot = chatBotResponse?.data?.[0];
        setReset({
          id,
          siteTitle: siteTitle?.en || "",
          siteDescription: siteDescription?.en || "",
          phoneNumber: phoneNumber || "",
          siteLogo: {
            id: siteLogoId || "",
            url: siteLogo?.urls?.[0]?.url || "",
          },
          topMessageBackground: topMessage?.backgroundColor || "",
          topMessageText: topMessage?.text || "",
          headerLogo: {
            id: headerLogoId || "",
            url: headerLogo?.urls?.[0]?.url || "",
          },
          footerLogo: {
            id: footerLogoId || "",
            url: footerLogo?.urls?.[0]?.url || "",
          },
          coverImage: {
            id: coverImageId || "",
            url: coverImage?.urls?.[0]?.url || "",
          },
          agencyPhoto: {
            id: agencyPhotoId || "",
            url: agencyPhoto?.urls?.[0]?.url || "",
          },
          colorScheme: {},
          socialLinks: [...(socialLinks || [])],
          agencyEmail: agencyEmail || email || "",
          agencyName: agencyName || "",
          promotionHeading: promotionHeading || "",
          promotionBgColor: promotionBgColor || "",
          promotionSeoImage: {
            id: promotionSeoImage?.id || "",
            url: promotionSeoImage?.urls?.[0]?.url || "",
          },
          promotionSeoDescription: promotionSeoDescription || "",
          promotionSeoTitle: promotionSeoTitle || "",
          upcomingSeoImage: {
            id: upcomingSeoImage?.id || "",
            url: upcomingSeoImage?.urls?.[0]?.url || "",
          },
          upcomingSeoDescription: upcomingSeoDescription || "",
          upcomingSeoTitle: upcomingSeoTitle || "",
          topSeoImage: {
            id: topSeoImage?.id || "",
            url: topSeoImage?.urls?.[0]?.url || "",
          },
          topSeoDescription: topSeoDescription || "",
          topSeoTitle: topSeoTitle || "",
          seoImage: {
            id: seoImage?.id || "",
            url: seoImage?.urls?.[0]?.url || "",
          },
          seoDescription: seoDescription || "",
          twitterCreator: twitterCreator || "",
          twitterSite: twitterSite || "",
          mobileMastheadImage: {
            id: mobileMastheadImage?.id || "",
            url: mobileMastheadImage?.urls?.[0]?.url || "",
          },
          floatingButtonEnabled: !!floatingButtonEnabled,
          whatsapp: whatsapp || "",
          salesTeamInfo: {
            name: salesTeamInfo?.name || "",
            registrationNumber: salesTeamInfo?.registrationNumber || "",
            currentEa: salesTeamInfo?.currentEa || "",
            licenseNumber: salesTeamInfo?.licenseNumber || "",
          },
          additionalEmail: [
            {
              email: agencyEmail || email || "",
            },
            ...additionalEmailConvert,
          ],
          // measuringId: measuringId || "",
          headerScript: headerScript || "",
          bodyScript: bodyScript || "",
          enquiryScript: enquiryScript || "",
          chatId: settingChatBot?.chatId || "",
          domain: domains[0].name,
        });
      })
      .catch(() => onOpenCreateAgencySettingModal());

  useEffect(() => {
    onFetchAgencySetting();
  }, []);

  const previewUrl = useMemo(() => {
    const domain = agencySettingData?.data?.domains?.find(
      (domain: TDomain) => !!domain?.primary
    );
    if (domain) {
      return (domain as TDomain)?.name || "";
    }
    return "";
  }, [agencySettingData?.data?.domains]);

  const [tabIndex, setTabIndex] = useState(0);
  const [form, setForm] = useState<any>(null);

  useEffect(() => {
    console.log("reset", reset);
  }, [reset]);

  return (
    <Form<EditAgencySettingSchema>
      resetValues={reset}
      onSubmit={onSubmit}
      validationSchema={editAgencySettingSchema}
      className="grid grid-cols-1 @container md:grid-cols-2 [&_.rizzui-input-label]:font-medium [&_.rizzui-input-label]:text-gray-900 mt-4"
      onInitForm={setForm}
    >
      {({
        control,
        register,
        watch,
        reset,
        setValue,
        formState: { errors, defaultValues, dirtyFields },
        setError,
      }) => {
        return (
          <>
            <div className="relative z-50 mb-2 flex flex-wrap items-center justify-between gap-2.5 @container">
              <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
                Website Settings
              </Title>
            </div>
            <Tab
              className="col-span-full"
              selectedIndex={tabIndex}
              onChange={(index) => setTabIndex(index)}
            >
              <Tab.List>
                <Tab.ListItem>Site identity</Tab.ListItem>
                <Tab.ListItem>Emails</Tab.ListItem>
                {/* <Tab.ListItem>Google Analytics</Tab.ListItem> */}
                <Tab.ListItem>Promotion setting</Tab.ListItem>
                <Tab.ListItem>Site colors</Tab.ListItem>
                <Tab.ListItem>Sales Team Info</Tab.ListItem>
                <Tab.ListItem>Scripts</Tab.ListItem>
                <Tab.ListItem>Social information</Tab.ListItem>
                <Tab.ListItem>Home SEO</Tab.ListItem>
                <Tab.ListItem>Promotion SEO</Tab.ListItem>
                <Tab.ListItem>Upcoming SEO</Tab.ListItem>
                <Tab.ListItem>Top SEO</Tab.ListItem>
                <Tab.ListItem>Floating Buttons</Tab.ListItem>
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel
                  data-name="site-identity"
                  className="flex flex-col gap-6"
                >
                  {/* <Controller
                    control={control}
                    name="domain"
                    render={({ field, fieldState, formState }) => (
                      <DomainInput
                        label="Custom Domain"
                        size="lg"
                        placeholder="Enter your domain"
                        className="col-span-full p-0"
                        {...field}
                        {...formState}
                        value={field.value}
                        error={fieldState.error?.message}
                        configId={agencySettingData?.data?.id}
                        // suffix={SUFFIX_DOMAIN}
                        inputClassName="[&>input]:pe-0"
                        setError={(errorStr: string) =>
                          setError("domain", {
                            message: errorStr,
                          })
                        }
                        isUpdate={formState.isDirty}
                      />
                    )}
                  /> */}
                  <Controller
                    control={control}
                    name="siteTitle"
                    render={({ field, fieldState, formState }) => (
                      <Input
                        label="Site title"
                        size="lg"
                        placeholder="Enter site title"
                        className="col-span-full"
                        {...field}
                        {...formState}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="siteDescription"
                    render={({
                      field: { onChange, value },
                      fieldState,
                      formState,
                    }) => (
                      <QuillEditor
                        value={value}
                        onChange={onChange}
                        label="Description"
                        className="col-span-full [&_.ql-editor]:min-h-[100px]"
                        labelClassName="font-medium text-gray-700 dark:text-gray-600 mb-1.5"
                        {...formState}
                        error={fieldState.error?.message}
                      />
                      // <Textarea
                      //   label="Site description"
                      //   size="lg"
                      //   placeholder="Enter site description"
                      //   className="col-span-full"
                      //   {...field}
                      //   {...formState}
                      //   value={field.value}
                      //   error={fieldState.error?.message}
                      // />
                    )}
                  />
                  <div className="flex md:flex-row flex-col gap-6">
                    <Controller
                      control={control}
                      name="phoneNumber"
                      render={({ field, fieldState, formState }) => (
                        <Input
                          label="Phone number"
                          size="lg"
                          placeholder="Enter phone number"
                          className="md:w-1/2 w-full"
                          {...field}
                          {...formState}
                          value={field.value}
                          error={fieldState.error?.message}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="whatsapp"
                      render={({ field, fieldState, formState }) => (
                        <Input
                          label="Whatsapp"
                          size="lg"
                          placeholder="Enter Whatsapp"
                          className="md:w-1/2 w-full"
                          {...field}
                          {...formState}
                          value={field.value}
                          error={fieldState.error?.message}
                        />
                      )}
                    />
                  </div>
                  <div className="flex md:flex-row flex-col gap-6">
                    <Controller
                      control={control}
                      name="twitterSite"
                      render={({ field, fieldState, formState }) => (
                        <Input
                          label="Twitter site"
                          size="lg"
                          placeholder="Enter Twitter site"
                          className="md:w-1/2 w-full"
                          {...field}
                          {...formState}
                          value={field.value}
                          error={fieldState.error?.message}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="twitterCreator"
                      render={({ field, fieldState, formState }) => (
                        <Input
                          label="Twitter Creator"
                          size="lg"
                          placeholder="Enter Twitter Creator"
                          className="md:w-1/2 w-full"
                          {...field}
                          {...formState}
                          value={field.value}
                          error={fieldState.error?.message}
                        />
                      )}
                    />
                  </div>
                  <div className="grid grid-cols-12 gap-4 md:gap-6">
                    <Controller
                      control={control}
                      name="siteLogo"
                      render={({ field: { onChange } }) => (
                        <SingleImageUpload
                          imageUrl={watch("siteLogo")?.url || ""}
                          title="Favicon"
                          setValue={onChange}
                          uploaderText="Upload favicon logo"
                          uploadContainerClass="w-full relative h-fit md:h-[150px] col-span-2"
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="headerLogo"
                      render={({ field: { onChange } }) => (
                        <SingleImageUpload
                          imageUrl={watch("headerLogo")?.url || ""}
                          title="Header Logo"
                          setValue={onChange}
                          uploaderText="Upload header logo"
                          uploadContainerClass="w-full relative h-fit md:h-[150px] col-span-5"
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="footerLogo"
                      render={({ field: { onChange } }) => (
                        <SingleImageUpload
                          imageUrl={watch("footerLogo")?.url || ""}
                          title="Footer Logo"
                          setValue={onChange}
                          uploaderText="Upload footer logo"
                          uploadContainerClass="w-full relative h-fit md:h-[150px] col-span-5"
                        />
                      )}
                    />
                  </div>
                  <Controller
                    control={control}
                    name="coverImage"
                    render={({ field: { onChange } }) => (
                      <SingleImageUpload
                        imageUrl={watch("coverImage")?.url || ""}
                        title="Masthead Background"
                        setValue={onChange}
                        uploaderText="Upload masthead background"
                        imageContainerClassName="w-full object-cover h-auto"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="mobileMastheadImage"
                    render={({ field: { onChange } }) => (
                      <SingleImageUpload
                        imageUrl={watch("mobileMastheadImage")?.url || ""}
                        title="Mobile Masthead Background"
                        setValue={onChange}
                        uploaderText="Upload mobile masthead background"
                        imageContainerClassName="w-full md:w-1/2 object-cover h-auto"
                      />
                    )}
                  />
                </Tab.Panel>
                <Tab.Panel data-name="emails" className="flex flex-col gap-6">
                  <MultipleEmailsField
                    name="additionalEmail"
                    agencyDefaultEmail={defaultValues?.agencyEmail || ""}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Tab.Panel>

                {/* <Tab.Panel data-name="Ga4" className="flex flex-col gap-2">
                  <div className="grid grid-cols-1 gap-2 w-full">
                    <div className="grid w-full items-start gap-3 grid-cols-1">
                      <Controller
                        control={control}
                        name="measuringId"
                        render={({ field, fieldState, formState }) => (
                          <Input
                            label="Measuring ID"
                            size="lg"
                            placeholder="G-XXXXX"
                            className="col-span-full"
                            {...field}
                            {...formState}
                            value={field.value || ""}
                            error={fieldState.error?.message}
                          />
                        )}
                      />
                    </div>
                  </div>
                </Tab.Panel> */}
                <Tab.Panel
                  data-name="promotion-setting"
                  className="flex flex-col gap-6"
                >
                  <Controller
                    control={control}
                    name="topMessageText"
                    render={({
                      field: { onChange, value },
                      fieldState,
                      formState,
                    }) => (
                      <QuillEditor
                        value={value}
                        onChange={onChange}
                        topMessageBackground={watch("topMessageBackground")}
                        label="Top Message Content"
                        className="col-span-full [&_.ql-editor]:min-h-[100px]"
                        labelClassName="font-medium text-gray-700 dark:text-gray-600 mb-1.5"
                        {...formState}
                        error={fieldState.error?.message}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="topMessageBackground"
                    render={({ field }) => (
                      <div className="flex justify-start items-center gap-3">
                        <span className="rizzui-input-label text-sm font-medium text-muted-foreground">
                          Top Message Background:
                        </span>
                        <ColorPickerPopover
                          value={field.value || ""}
                          onChange={(value: string) => {
                            field.onChange(value);
                          }}
                          className="w-20 h-[32px] rounded border border-solid"
                        />
                      </div>
                    )}
                  />
                </Tab.Panel>
                <Tab.Panel
                  data-name="site-colors"
                  className="flex flex-col gap-6"
                >
                  {colorSchemes?.map((scheme, index) => (
                    <ColorSchemaField
                      key={scheme.header + index}
                      title={scheme.header}
                      name={`colorScheme.${scheme.header}`}
                      control={control}
                      register={register}
                      errors={errors}
                      colors={scheme.colors}
                      colorScheme={scheme}
                      previewUrl={previewUrl}
                    />
                  ))}
                </Tab.Panel>
                <Tab.Panel
                  data-name="sale-team-infors"
                  className="flex flex-col gap-6"
                >
                  <Controller
                    control={control}
                    name="salesTeamInfo.name"
                    render={({ field, fieldState, formState }) => (
                      <Input
                        label="Name"
                        size="lg"
                        placeholder="Enter name"
                        className="col-span-full"
                        {...field}
                        {...formState}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="salesTeamInfo.registrationNumber"
                    render={({ field, fieldState, formState }) => (
                      <Input
                        label="Registration Number"
                        size="lg"
                        placeholder="Enter registration number"
                        className="col-span-full"
                        {...field}
                        {...formState}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="salesTeamInfo.currentEa"
                    render={({ field, fieldState, formState }) => (
                      <Input
                        label="Current EA"
                        size="lg"
                        placeholder="Enter current ea"
                        className="col-span-full"
                        {...field}
                        {...formState}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="salesTeamInfo.licenseNumber"
                    render={({ field, fieldState, formState }) => (
                      <Input
                        label="License Number"
                        size="lg"
                        placeholder="Enter license number"
                        className="col-span-full"
                        {...field}
                        {...formState}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )}
                  />
                </Tab.Panel>
                <Tab.Panel data-name="scripts" className="flex flex-col gap-6">
                  <Controller
                    control={control}
                    name="headerScript"
                    render={({ field, fieldState, formState }) => (
                      <Textarea
                        label="Head Scripts"
                        size="lg"
                        placeholder="Enter head scripts"
                        className="col-span-full"
                        {...field}
                        {...formState}
                        value={field.value || ""}
                        error={fieldState.error?.message}
                      />
                    )}
                  />
                  <div className="grid grid-cols-12 gap-6">
                    <Controller
                      control={control}
                      name="bodyScript"
                      render={({ field, fieldState, formState }) => (
                        <Textarea
                          label="Body Scripts"
                          size="lg"
                          placeholder="Enter body scripts"
                          className="col-span-full"
                          {...field}
                          {...formState}
                          value={field.value || ""}
                          error={fieldState.error?.message}
                        />
                      )}
                    />
                  </div>
                  <div className="grid grid-cols-12 gap-6">
                    <Controller
                      control={control}
                      name="enquiryScript"
                      render={({ field, fieldState, formState }) => (
                        <Textarea
                          label="Enquire Scripts"
                          size="lg"
                          placeholder="Enter enquire scripts"
                          className="col-span-full"
                          {...field}
                          {...formState}
                          value={field.value || ""}
                          error={fieldState.error?.message}
                        />
                      )}
                    />
                  </div>
                </Tab.Panel>
                <Tab.Panel
                  data-name="social-infomation"
                  className="flex flex-col gap-6"
                >
                  <SocialLinkField
                    title="Social links"
                    name="socialLinks"
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Tab.Panel>
                <Tab.Panel data-name="home-seo" className="flex flex-col gap-6">
                  <Controller
                    control={control}
                    name="seoDescription"
                    render={({ field, fieldState, formState }) => (
                      <Textarea
                        label="SEO description"
                        size="lg"
                        placeholder="Enter home seo description"
                        className="col-span-full"
                        {...field}
                        {...formState}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )}
                  />
                  <div className="grid grid-cols-12 gap-6">
                    <Controller
                      control={control}
                      name="seoImage"
                      render={({ field: { onChange } }) => (
                        <SingleImageUpload
                          imageUrl={watch("seoImage")?.url || ""}
                          title="SEO Image"
                          setValue={onChange}
                          uploaderText="Upload SEO image"
                          uploadContainerClass="w-full h-full md:h-[150px] md:col-span-2 col-span-12"
                        />
                      )}
                    />
                  </div>
                </Tab.Panel>
                <Tab.Panel
                  data-name="promotion-seo"
                  className="flex flex-col gap-6"
                >
                  <Controller
                    control={control}
                    name="promotionSeoTitle"
                    render={({ field, fieldState, formState }) => (
                      <Input
                        label="SEO Title"
                        size="lg"
                        placeholder="Enter seo title"
                        className="col-span-full"
                        {...field}
                        {...formState}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="promotionSeoDescription"
                    render={({ field, fieldState, formState }) => (
                      <Textarea
                        label="SEO description"
                        size="lg"
                        placeholder="Enter seo description"
                        className="col-span-full"
                        {...field}
                        {...formState}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )}
                  />
                  <div className="grid grid-cols-12 gap-6">
                    <Controller
                      control={control}
                      name="promotionSeoImage"
                      render={({ field: { onChange } }) => (
                        <SingleImageUpload
                          imageUrl={watch("promotionSeoImage")?.url || ""}
                          title="SEO Image"
                          setValue={onChange}
                          uploaderText="Upload SEO image"
                          uploadContainerClass="w-full h-full md:h-[150px] md:col-span-2 col-span-12"
                        />
                      )}
                    />
                  </div>
                </Tab.Panel>
                <Tab.Panel
                  data-name="upcoming-seo"
                  className="flex flex-col gap-6"
                >
                  <Controller
                    control={control}
                    name="upcomingSeoTitle"
                    render={({ field, fieldState, formState }) => (
                      <Input
                        label="SEO Title"
                        size="lg"
                        placeholder="Enter seo title"
                        className="col-span-full"
                        {...field}
                        {...formState}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="upcomingSeoDescription"
                    render={({ field, fieldState, formState }) => (
                      <Textarea
                        label="SEO description"
                        size="lg"
                        placeholder="Enter seo description"
                        className="col-span-full"
                        {...field}
                        {...formState}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )}
                  />
                  <div className="grid grid-cols-12 gap-6">
                    <Controller
                      control={control}
                      name="upcomingSeoImage"
                      render={({ field: { onChange } }) => (
                        <SingleImageUpload
                          imageUrl={watch("upcomingSeoImage")?.url || ""}
                          title="SEO Image"
                          setValue={onChange}
                          uploaderText="Upload SEO image"
                          uploadContainerClass="w-full h-full md:h-[150px] md:col-span-2 col-span-12"
                        />
                      )}
                    />
                  </div>
                </Tab.Panel>
                <Tab.Panel data-name="top-seo" className="flex flex-col gap-6">
                  <Controller
                    control={control}
                    name="topSeoTitle"
                    render={({ field, fieldState, formState }) => (
                      <Input
                        label="SEO Title"
                        size="lg"
                        placeholder="Enter seo title"
                        className="col-span-full"
                        {...field}
                        {...formState}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="topSeoDescription"
                    render={({ field, fieldState, formState }) => (
                      <Textarea
                        label="SEO description"
                        size="lg"
                        placeholder="Enter seo description"
                        className="col-span-full"
                        {...field}
                        {...formState}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )}
                  />
                  <div className="grid grid-cols-12 gap-6">
                    <Controller
                      control={control}
                      name="topSeoImage"
                      render={({ field: { onChange } }) => (
                        <SingleImageUpload
                          imageUrl={watch("topSeoImage")?.url || ""}
                          title="SEO Image"
                          setValue={onChange}
                          uploaderText="Upload SEO image"
                          uploadContainerClass="w-full h-full md:h-[150px] md:col-span-2 col-span-12"
                        />
                      )}
                    />
                  </div>
                </Tab.Panel>
                <Tab.Panel
                  data-name="floating-buttons"
                  className="flex flex-col gap-6"
                >
                  <Controller
                    control={control}
                    name={`floatingButtonEnabled`}
                    render={({ field: { value } }) => (
                      <div className="relative h-full flex flex-col">
                        <div className="flex-1 flex flex-col justify-center">
                          <VisibleToggle
                            value={!!value}
                            visibleTitle="Show floating buttons"
                            invisibleTitle="Hide floating buttons"
                            onChange={(value: boolean) =>
                              setValue(`floatingButtonEnabled`, value, {
                                shouldDirty: true,
                              })
                            }
                          />
                        </div>
                      </div>
                    )}
                  />
                </Tab.Panel>
              </Tab.Panels>
            </Tab>

            <div
              className={cn(
                "col-span-full -mb-8 flex items-center justify-end gap-4 bg-white p-2 sticky bottom-0 left-0 right-0 z-[99]"
              )}
            >
              <Button
                type="button"
                variant="outline"
                className="w-full @xl:w-auto"
                onClick={() => reset()}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                id={"form-settings"}
                isLoading={isLoading}
                className="w-full @xl:w-auto"
              >
                Update
              </Button>
            </div>
          </>
        );
      }}
    </Form>
  );
}
