import { z } from "zod";

function isUrlWithoutProtocol(url: string) {
  const urlWithoutProtocolRegex = /^(?:www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;
  return urlWithoutProtocolRegex.test(url);
}

export const landingPageSchema = z.object({
  domain: z.string().refine((value) => isUrlWithoutProtocol(value), {
    message: "Invalid Url",
  }),
  name: z.string().min(1, { message: "This field is required" }),
  project: z
    .object({
      label: z.string(),
      value: z.string(),
    })
    .optional(),
  user: z.string().optional(),
  layoutOptionId: z.string().optional(),
});

export type TLandingPageSchemaForm = z.infer<typeof landingPageSchema>;
