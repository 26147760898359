import { RequestError } from "@/utilities/types/requests";
import { useCallback, useRef, useState } from "react";
import toast from "react-hot-toast";
export interface UseApiProps<T> {
  request: ((...args: any[]) => Promise<T>) | null;
  enableToast?: boolean;
  reValidate?: boolean;
}

export default function useApi<T>({
  request,
  enableToast = false,
  reValidate = true,
}: UseApiProps<T>) {
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<T | null>(null);
  const [error, setError] = useState<RequestError>();

  const prevArgs = useRef<any>(null);

  const requestFunc = useCallback(
    async (...args: any[]) => {
      setLoading(true);
      setError(undefined);
      if (reValidate) setResponse(null);
      let toastId;
      if (enableToast) {
        toastId = toast.loading("Loading...");
      }
      try {
        if (typeof request !== "function") {
          return;
        }
        const response = await request(...args);
        setResponse(response);
        if (enableToast) {
          toast.success("Successfully", { id: toastId });
        }
        prevArgs.current = args;
        return response;
      } catch (error: any) {
        setError(error as RequestError);
        if (enableToast) {
          toast.error(error?.message || "Something went wrong!", {
            id: toastId,
          });
        }
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [request]
  );

  return {
    loading,
    request: requestFunc,
    refetch: async () => {
      return await requestFunc(...prevArgs.current);
    },
    response,
    error,
  };
}
