import useApi from "@/hooks/useApi";
import { MAPPING_NAME } from "@/utilities/functions/ldp-options";
import LandingPageOptionRepository from "@/utilities/repositories/LandingPageOptions";
import { ILandingPage } from "@/utilities/types/LandingPage";
import { ILandingPageOption } from "@/utilities/types/LandingPageOption";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { Select, SelectOption } from "rizzui";

export interface SelectOptionDomainProps {
  isAdding?: boolean;
  optionsMapping: ILandingPage["optionMappings"];
  ldpId: string;
  ldpOptsListing: ILandingPageOption[];
  onSelectOption?: (optionId: string) => void;
  defaultValue?: string;
}

export default function SelectOptionDomain({
  isAdding = false,
  optionsMapping,
  ldpId,
  ldpOptsListing,
  onSelectOption,
  defaultValue,
}: SelectOptionDomainProps) {
  const selectOpts: SelectOption[] = useMemo(() => {
    return ldpOptsListing.map((option) => ({
      value: option.id,
      label: MAPPING_NAME[option.name] || "",
      ...option,
    }));
  }, [ldpOptsListing]);

  const [optionValue, setOptionValue] = useState<string | undefined>(
    defaultValue
  );

  const { request: attachOption } = useApi({
    request: LandingPageOptionRepository.attachOption,
  });

  const { request: activeOption } = useApi({
    request: LandingPageOptionRepository.activeOption,
    enableToast: true,
  });

  const handleSelectOption = async (optionId: string) => {
    if (isAdding) return;
    const isExistingOption = optionsMapping.find(
      (option) => option.id === optionId
    );
    try {
      if (!isExistingOption) {
        // Need attach option before  active if option not exist before
        await attachOption({
          landingPageId: ldpId,
          layoutOptionId: optionId,
        });
      }
      await activeOption({
        landingPageId: ldpId,
        layoutOptionId: optionId,
        active: true,
      });
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  return (
    <Select
      options={selectOpts}
      value={optionValue}
      onChange={async (optionId: string) => {
        onSelectOption?.(optionId);
        await handleSelectOption(optionId);
        setOptionValue(optionId);
      }}
      getOptionValue={(option: SelectOption) => option.value}
      displayValue={(selected: string) => {
        const findOption = selectOpts?.find((option) => {
          return option.value === selected;
        });

        // @ts-ignore
        // setMessageSelected(findOption);
        // @ts-ignore
        return findOption?.label || "";
      }}
      dropdownClassName={"z-modal [&_li>div]:w-full"}
      getOptionDisplayValue={(option) => option.label}
    />
  );
}
